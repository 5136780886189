import * as React from "react"
import DownloadFilePage from "@/components/DownloadFilePage"
import { useGTMInit} from '@/hooks/useGTM.js'



const Index = () => {
  React.useEffect(()=>{
    useGTMInit('support','XREAL_ADA_MANUAL_pdf')
  },[])
  return (
  <>
    <DownloadFilePage
      downloadURL={`${process.env.resourceUrl}docs/manual/XREAL_ADA_MANUAL.pdf`}
    />
  </>
)


}

export default Index